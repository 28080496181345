import type { DocumentData, FirestoreDataConverter } from '@firebase/firestore'
import dayjs from 'dayjs'
import dayjsRecur from 'dayjs-recur'

dayjs.extend(dayjsRecur)
import z from 'zod'

export const playgroundList = ['Yes', 'No', 'Within 5min walk'] as const
export type Playground = (typeof playgroundList)[number]
export const shelterList = ['Indoors', 'Outdoors', 'Hybrid'] as const
export type Shelter = (typeof shelterList)[number]
export const priceList = ['Free', '£', '££', '£££'] as const
export type Price = (typeof priceList)[number]
export const ageList = ['Babies', 'Toddlers', 'Pre-Schoolers', 'Primary', 'Secondary'] as const
export type Age = (typeof ageList)[number]
export const typeList = [
  'Hosts birthday parties',
  'Animals',
  'Cinema',
  'Climbing',
  'Energy-buster',
  'Escape Rooms',
  'Big Day Out',
  'Gaming',
  'Library',
  'Museum',
  'Play Cafe',
  'Pottery',
  'Pub/Restaurant',
  'Soft play',
  'Swimming',
  'Vehicles'
] as const
export type Type = (typeof typeList)[number]
export const bookingTypeList = [null, 'Advance Booking Required', 'Advance Booking Required - weekends'] as const
export type BookingType = (typeof bookingTypeList)[number]
export const parkingPriceList = ['N/A', 'Pay and Display', 'Free Parking'] as const
export type ParkingPrice = (typeof parkingPriceList)[number]

export const eventTypeList = ['Event', 'Easter', 'Summer Camp', 'Summer Holiday Events', 'Halloween', 'Christmas', 'Christmas - Meet Santa', 'Christmas - SEN', 'Bonfire Night'] as const
export const activeEventTypeList = eventTypeList.filter((s) => s === 'Event' || s === 'Halloween')
export type EventType = (typeof eventTypeList)[number]

export const playgroupTagsList = ['SEN', 'Dads', 'Religious Content'] as const
export type PlaygroupTags = (typeof playgroupTagsList)[number]

const shelterSearchList = ['Indoors', 'Outdoors', "Don't mind"] as const
export const unTypedShelterSearch = shelterSearchList.map((s) => s as string)

export type ShelterSearch = (typeof shelterSearchList)[number]

const playgroupTimeSearchList = ['AM', 'PM', "Don't mind"] as const
export const unTypedPlaygroupTimeSearch = playgroupTimeSearchList.map((s) => s as string)
export type PlaygroupTimeSearch = (typeof playgroupTimeSearchList)[number]

export interface OpeningTimes {
  day: string
  from: string
  to: string
  from2?: string
  to2?: string
  isClosed: boolean
}

export interface ActivityTimings {
  openingTimes: OpeningTimes[]
  is247: boolean
  externalOpeningTimes: boolean
  externalWebsiteUrl: string
}

export const activitySchema = z.object({
  name: z.string().nonempty('This field is required'),
  description: z.string().nonempty('This field is required'),
  playground: z.enum(playgroundList),
  shelter: z.enum(shelterList),
  price: z.enum(priceList),
  age: z.array(z.enum(ageList)),
  type: z.array(z.enum(typeList)),
  address: z.string().nonempty('This field is required'),
  url: z.string().url('Please enter a valid URL'),
  logoUrl: z.string().url('Please enter a valid URL'),
  timings: z.object({
    openingTimes: z.array(
      z.object({
        day: z.string().nonempty('This field is required'),
        from: z.string().nonempty('This field is required'),
        to: z.string().nonempty('This field is required'),
        from2: z.string().optional(),
        to2: z.string().optional(),
        isClosed: z.boolean()
      })
    ),
    is247: z.boolean(),
    externalOpeningTimes: z.boolean(),
    externalWebsiteUrl: z.string().optional()
  }),
  dedicatedParking: z.boolean(),
  payAndDisplay: z.enum(parkingPriceList).optional(),
  longitude: z.number(),
  latitude: z.number(),
  bookingType: z.string().optional(),
  bareBones: z.boolean().optional()
})

export interface IActivity {
  id: string
  name: string
  description: string
  playground: Playground
  shelter: Shelter
  price: Price
  age: Age[]
  type: Type[]
  address: string
  url: string
  logoUrl: string
  timings: ActivityTimings
  dedicatedParking: boolean
  payAndDisplay?: boolean
  longitude: number
  latitude: number
  bookingType: BookingType
  bareBones?: boolean
}

export interface IEvent {
  id: string
  name: string
  description: string
  price: Price
  shelter: Shelter
  age: Age[]
  tags: Type[]
  url: string
  logoUrl: string
  bookingType: BookingType
  locationId: string
  dates: Date[]
  time: { start: string; end: string }
  type: EventType
  live: boolean
  mode: 'Dates' | 'Reccuring'
  recurringEvent: { amount: number; unit: 'weeks'; start: Date; recurrenceText: string }
}

export interface IEventWithActivity extends IEvent {
  activity: IActivity
}

export function getEventDates(event: IEvent) {
  if (event.mode === 'Reccuring' && event.recurringEvent) {
    const recurrence = dayjs(event.recurringEvent.start).recur().every(event.recurringEvent.amount, event.recurringEvent.unit)

    return [dayjs(event.recurringEvent.start).toDate(), ...recurrence.next(25).map((d) => d.toDate())].filter((d) => dayjs(d).endOf('day').isAfter(dayjs()))
  } else {
    return event.dates.filter((d) => dayjs(d).endOf('day').isAfter(dayjs())).map((d) => dayjs(d).toDate())
  }
}

export interface Review {
  id: string
  review: string
  name: string
  date: Date
  activityId: string
  upVotes: number
}

export interface Feedback {
  id?: string
  email: string
  name: string
  message: string
  date: string
}
export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6
export interface IPlaygroup {
  id: string
  name: string
  description: string
  locationName: string
  address: string
  shelter: Shelter
  longitude: number
  latitude: number
  day: DayOfWeek[]
  startTime: string
  endTime: string
  age: Age[]
  termTimeOnly: boolean
  bookInAdvance: boolean
  url: string
  notes: string
  contactAddress: string
  tags: PlaygroupTags[]
}

export const dayOfWeekOptions: { label: string; value: DayOfWeek }[] = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 0 }
]
export function dayOfWeek(day: number) {
  return dayOfWeekOptions.map((d) => d.label)[day - 1]
}

export interface IUserData {
  favourites: string[]
}

export interface AddressSearchResult {
  latitude: number
  longitude: number
  type: string
  name: string
  number: any
  postal_code: any
  street: any
  confidence: number
  region: string
  region_code: any
  county: string
  locality: string
  administrative_area: any
  neighbourhood: string
  country: string
  country_code: string
  continent: string
  label: string
}

export function defaultConverter<T>(extraFromMapping?: (data: DocumentData) => Partial<T>): FirestoreDataConverter<T> {
  return {
    toFirestore(modelObject: T) {
      return { ...modelObject } as DocumentData
    },
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options)
      convertDatesOnObject(data)

      return { id: snapshot.id, ...data, ...(extraFromMapping ? extraFromMapping(data) : {}) } as T
    }
  }
}

function convertDatesOnObject(data: Record<string, any>) {
  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'object' && value && !Array.isArray(value)) {
      if (value.nanoseconds !== undefined && value.seconds !== undefined) {
        data[key] = value.toDate()
      } else {
        convertDatesOnObject(value)
      }
    } else if (Array.isArray(value)) {
      data[key] = value.map((v) => {
        if (v.nanoseconds !== undefined && v.seconds !== undefined) {
          return v.toDate()
        }
        return v
      })
    }
  }
}
